.content {
    &.content--margin-top {
        margin-top: 48px;
    }

    &.content--margin-bottom {
        margin-bottom: 48px;
    }
}
.full-width-image{
    left: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
	max-width: 100vw;
	position: relative;
	right: 50%;
	width: 100vw;
}
p+p{
    margin-top: 15px;
}
