.section {
    position: relative;
    &.section--margin-top {
        margin-top: 64px;
        @include media-breakpoint-up(lg) {
            margin-top: 120px;
        }
    }

    &.section--margin-bottom {
        margin-bottom: 64px;
        @include media-breakpoint-up(lg) {
            margin-bottom: 120px;
        }
    }

    &.section--padding-top {
        padding-top: 64px;

        @include media-breakpoint-up(lg) {
            padding-top: 120px;
        }
    }

    &.section--padding-bottom {
        padding-bottom: 64px;

        @include media-breakpoint-up(lg) {
            padding-bottom: 120px;
        }
    }

    &.section--bg-light {
        background-color: $color__gray--light;

        .page__main > & {
            &:last-of-type {
                body:not([data-editmode='true']) & {
                    border-bottom: 1px solid $color__gray;
                }
            }
        }

        body[data-template='home'] .page__main > & {
            &:first-of-type {
                position: relative;

                &::before {
                    content: "";
                    position: absolute;
                    z-index: -1;
                    top: -80px;
                    left: 0;
                    width: 100%;
                    height: 80px;
                    background-color: $color__gray--light;
                    pointer-events: none;
                    
                }
            }
        }
    }

    &.section--bg-dark {
        background-color: $color__primary;
    }

    &.section--bg-image {
        position: relative;
        z-index: 0;
    }

    &__background-image {
        position: absolute;
        z-index: -1;
        top: 0; left: 0;
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    body[data-template='home'] .page__main > & {
        header {
            margin-bottom: 48px;
            @include media-breakpoint-down(md) {
                margin-bottom: 24px;
                
            }
        }
    }
}
