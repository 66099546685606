*:not(.stretched-link)::before,
*:not(.stretched-link)::after {
    pointer-events: none;
}

:root {
    @if $font-size-root != null {
        --#{$prefix}root-font-size: #{$font-size-root};
    }
    --#{$prefix}body-font-family: #{inspect($font-family-base)};
    @include rfs($font-size-base, --#{$prefix}body-font-size);
    --#{$prefix}body-font-weight: #{$font-weight-base};
    --#{$prefix}body-line-height: #{$line-height-base};
    @if $body-text-align != null {
        --#{$prefix}body-text-align: #{$body-text-align};
    }
    --#{$prefix}body-color: #{$body-color};
    --#{$prefix}body-bg: #{$body-bg};

    --#{$prefix}border-width: #{$border__width};
    --#{$prefix}border-style: #{$border__style};
    --#{$prefix}border-color: #{$border__color};
    --#{$prefix}border-color-translucent: #{$border-color-translucent};

    --border-color: #{$border__color};
    --border-radius: #{$border__radius};
    --border-style: #{$border__style};
    --border-width: #{$border__width};

    --color-primary: #{$color__primary};
    --color-primary-rgb: #{red($color__primary)},#{green($color__primary)},#{blue($color__primary)};
    --color-primary-font: #{$color__primary--font};
    --color-primary-font-rgb: #{red($color__primary--font)},#{green($color__primary--font)},#{blue($color__primary--font)};
    --color-primary-hover: #{$color__primary--hover};
    --color-primary-hover-rgb: #{red($color__primary--hover)},#{green($color__primary--hover)},#{blue($color__primary--hover)};
    --color-primary-disabled: #{$color__primary--disabled};
    --color-primary-disabled-rgb: #{red($color__primary--disabled)},#{green($color__primary--disabled)},#{blue($color__primary--disabled)};
    --color-secondary: #{$color__secondary};
    --color-secondary-rgb: #{red($color__secondary)},#{green($color__secondary)},#{blue($color__secondary)};
    --color-success: #{$color__success};
    --color-success-rgb: #{red($color__success)},#{green($color__success)},#{blue($color__success)};
    --color-danger: #{$color__danger};
    --color-danger-rgb: #{red($color__danger)},#{green($color__danger)},#{blue($color__danger)};
    --color-danger-font: #{$color__danger--font};
    --color-danger-font-rgb: #{red($color__danger--font)},#{green($color__danger--font)},#{blue($color__danger--font)};
    --color-note: #{$color__note};
    --color-note-rgb: #{red($color__note)},#{green($color__note)},#{blue($color__note)};
    --color-note-font: #{$color__note--font};
    --color-note-font-rgb: #{red($color__note--font)},#{green($color__note--font)},#{blue($color__note--font)};
    --color-gray: #{$color__gray};
    --color-gray-rgb: #{red($color__gray)},#{green($color__gray)},#{blue($color__gray)};
    --color-gray-light: #{$color__gray--light};
    --color-gray-light-rgb: #{red($color__gray--light)},#{green($color__gray--light)},#{blue($color__gray--light)};
    --color-black: #{$color__black};
    --color-black-rgb: #{red($color__black)},#{green($color__black)},#{blue($color__black)};
    --color-white: #{$color__white};
    --color-white-rgb: #{red($color__white)},#{green($color__white)},#{blue($color__white)};
    --color-font: #{$color__font};
    --color-font-rgb: #{red($color__font)},#{green($color__font)},#{blue($color__font)};
    --color-font-negative: #{$color__font--negative};
    --color-font-negative-rgb: #{red($color__font--negative)},#{green($color__font--negative)},#{blue($color__font--negative)};
    --color-breaking: #{$color__breaking};
    --color-breaking-rgb: #{red($color__breaking)},#{green($color__breaking)},#{blue($color__breaking)};
    --color-breaking-font: #{$color__breaking--font};
    --color-breaking-font-rgb: #{red($color__breaking--font)},#{green($color__breaking--font)},#{blue($color__breaking--font)};
    --color-breaking-hover: #{$color__breaking--hover};
    --color-breaking-hover-rgb: #{red($color__breaking--hover)},#{green($color__breaking--hover)},#{blue($color__breaking--hover)};
    --color-breaking-hover-font: #{$color__breaking--hover-font};
    --color-breaking-hover-font-rgb: #{red($color__breaking--hover-font)},#{green($color__breaking--hover-font)},#{blue($color__breaking--hover-font)};
    --color-ccm: #{$color__ccm};
    --color-ccm-rgb: #{red($color__ccm)},#{green($color__ccm)},#{blue($color__ccm)};
    --color-ccm-font: #{$color__ccm--font};
    --color-ccm-font-rgb: #{red($color__ccm--font)},#{green($color__ccm--font)},#{blue($color__ccm--font)};

    --filter-white: #{$filter__white};
    --filter-primary: #{$filter__primary};
    --filter-primary-hover: #{$filter__primary--hover};
    --filter-primary-disabled: #{$filter__primary--disabled};
    --filter-danger: #{$filter__danger};
    --filter-note: #{$filter__note};
    --filter-gray: #{$filter__gray};
    --filter-gray-light: #{$filter__gray--light};
    --filter-font: #{$filter__font};
    --filter-breaking-icon: #{$filter__breaking--icon};
    --filter-breaking-hover-icon: #{$filter__breaking--hover-icon};

    --font-weight-normal: #{$font__weight--normal};
    --font-weight-bold: #{$font__weight--bold};

    @each $breakpoint, $container-max-width in $container-max-widths {
        --container-max-width-#{$breakpoint}: #{$container-max-width};
    }
    @each $breakpoint, $container-padding-size in $container-padding-sizes {
        --container-padding-size-#{$breakpoint}: #{$container-padding-size};
    }
    @each $breakpoint, $grid-breakpoint in $grid-breakpoints {
        --grid-breakpoint-#{$breakpoint}: #{$grid-breakpoint};
    }
    --grid-gutter-width: #{$grid-gutter-width};

    --header-height: #{$header__height};
    --header-height-unpinned: #{$header__height--unpinned};
    --header-height-mobile: #{$header__height--mobile};

    --main-navigation-mode: #{$main-navigation__mode};

    @if $off-canvas__desktop {
        --off-canvas-desktop: 1;
    } @else {
        --off-canvas-desktop: 0;
    }
    --off-canvas-breakpoint: #{$off-canvas__breakpoint};

    --z-index-header: #{$z-index__header};
    --z-index-off-canvas: #{$z-index__off-canvas};

    &.body-overflow-hidden {
        scroll-behavior: auto !important;
    }
}

body {
    @include fluid-type(map-get($grid-breakpoints, sm), map-get($grid-breakpoints, xxl), 16px, 22px);
    line-height: $font__line-height;
}
.bg-gray-dark{
    background-color: $color__gray--dark;
    .border-top{
        border-top-color: rgba(#D2D9E8,0.25)!important;
    }
}
.content-small{

    @include media-breakpoint-down(md) {
        padding: 0!important;
    }
}
