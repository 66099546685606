footer.page__footer{
    h2,p,a{
        color: $color__white;
    }
    .address{
        p,a{
            @include fluid-type(map-get($grid-breakpoints, sm), map-get($grid-breakpoints, xxl), 16px, 19px);
            font-weight: 400;
        }
        a{
            &::before{
                border-color: $color__white;
                bottom: -2px;
            }
        }
        a.button{
            margin-top: 15px;
            font-weight: 700;
            padding-bottom: 10px;
            @include media-breakpoint-up(lg) {
                padding-bottom: 16px;
            }
            span{
                padding-bottom: 0;
                background-image: none;
            }
            &:hover,
            &.focus-within {
                background-color: $color__white;
                color: $color__font;
            }

            &:focus-within {
                background-color: $color__white;
                color: $color__font;
            }
        }
    }
    .meta-navigation--legal .meta-navigation__button{
        color: $color__white;
    }
    .footer-bottom .meta-navigation{
        justify-content: flex-start !important;
        @include media-breakpoint-up(md) {
            justify-content: center !important;
        }
        .meta-navigation__list{
            margin: 0;
            flex-direction: column;
            @include media-breakpoint-up(md) {
                flex-direction: row;
                margin: 0 -24px;
            }
            .meta-navigation__item{
                margin: 5px 0;

                display: flex;
                @include media-breakpoint-up(md) {
                    padding: 0 12px;
                    margin: 0;
                    border-left: 1px solid $color__white;
                }
                button,
                a{
                    line-height: 1;
                    color: $color__white;
                   
                }
                &:first-of-type{
                    border-left: 0;
                }
            }
        }
    }
    .svg-link-item{
        position: relative;
        @include transition;
        margin-top: 25px;
        a{
            &::before{
                display: none;
            }
        }
        &:first-of-type{
            margin-top: 0;
        }
        @include media-breakpoint-up(md) {
            margin-top: 0;
        }
        &:hover,
        &.focus-within {
            a{
                transform: translateY(-10px);
            }

        }

        &:focus-within {
            a{
                transform: translateY(-10px);
              
            }
        }

        &:after{
            content: "";
            position: absolute;
            right: 0;
            top: 10%;
            height: 80%;
            width: 1px;
            background-color: rgba(#D2D9E8,0.25);
            @include media-breakpoint-down(md) {
                display: none;
            }
        }
        &:last-of-type{
            &:after{
                display: none
            }
        }
    }
    .meta-navigation--social{
        .meta-navigation__link{
            background-color: transparent;
            img{
                @include recolor($color__gray-list);
                @include transition;
            }
            &:hover,
            &.focus-within {
                @include recolor($color__primary);
            }

            &:focus-within {
                @include recolor($color__primary);
            }
        }
        .button--icon{
            padding-left: 5px;
            padding-right: 5px;
        }
        .meta-navigation__item{
            display: flex;
            align-items: center;
            .meta-navigation__link{
                background-image: none;
                img{
                    height: 32px;
                }
            }
            &:first-of-type{
                .meta-navigation__link{
                    img{
                        height: 32px;
                    }
                }
            }
        }
    }
    .meta-navigation--accessibility{
        .meta-navigation__item{
            a{
                padding: 12px 0;
                span.icon img{
                    @include recolor($color__gray-list)
                }
                span:not(.icon){
                    font-weight: 400;
                    @include fluid-type(map-get($grid-breakpoints, sm), map-get($grid-breakpoints, xxl), 16px, 19px);
                }
                &:before{
                   border-color: $color__white;
                   bottom: 12px;
                   left: 32px;
                   width: calc(100% - 32px);
                }
                @include hover-focus-visible-within{
                    &:before{
                        width: 10px;
                    }
                }
            }
        }
    }
    .linklist{
        position: relative;
        &:before{
            content: "";
            width: 100%;
            padding-top: calc(100vw * 0.29);
            height: auto;
            background-image: url('/assets/website/img/sillhouette.svg');
            background-size: contain;
            position: absolute;
            background-repeat: no-repeat;
            bottom: 0;
            left: 0;
            @include media-breakpoint-up(md) {
                width: 45vw;
                padding-top: calc(45vw * 0.29);
            }
        }
        @include media-breakpoint-down(md) {
            padding-bottom: 250px!important;
        }
        @include media-breakpoint-down(sm) {
            padding-bottom: 180px!important;
        }
    }
    .footer-bottom .meta-navigation .meta-navigation__list .meta-navigation__item a,
    .meta-navigation--legal .meta-navigation__button{
        font-weight: 400;
    }
}
.top-link{
    button{
        display: flex;
        justify-content: center;
        align-items: center;
        .icon{
            display: flex;
            justify-content: center;
        }
        width: 58px;
        height: 58px;
        border-radius: 30px;
        background-color: $color__white;
        img{
            @include recolor($color__primary);
            transform: rotate(-90deg);
            height: 20px;
        }
        &:hover,
        &.focus-within {
            background-color: $color__primary;
            img{
                @include recolor($color__white)
            }
        }

        &:focus-within {
            img{
                @include recolor($color__white)
            }
        }
    }
    &--sticky{
        button{
            box-shadow: 0px 0px 5px rgba($color__gray--dark,0.5);
        }
    }
}
