a {
    font-weight: $font__weight--bold;
    text-decoration: none;
    text-decoration-thickness: .08em;
    color: $color__font;
    // border-radius: 4px;
    outline: 0;
    transition: color .2s linear, outline .15s ease-in-out;
    // .body[data-editmode='true'] &{

    // }
    &.underline-link,
    span {
        position: relative;

        background-color: rgba(0, 0, 0, 0);
        background-image: linear-gradient(0deg,
                $color__primary 0%,
                $color__primary 0%);
        background-repeat: no-repeat;
        background-size: 100% 2px;
        background-position: left bottom;

        padding-bottom: 4px;

        @include transition();
    }

    &:hover,
    &:focus-visible {

        &.underline-link,
        span {
            background-size: 30px 2px;
        }
    }

    .content--intro-default-custom &,
    .content--accordion &,
    .content--editor & {
        position: relative;

        background-color: rgba(0, 0, 0, 0);
        background-image: linear-gradient(0deg,
                $color__primary 0%,
                $color__primary 0%);
        background-repeat: no-repeat;
        background-size: 100% 2px;
        background-position: left bottom;

        padding-bottom: 2px;

        @include transition();

        &:hover,
        &:focus-visible {
            background-size: 30px 2px;
        }

        &.arrow {
            color: $color__font;
            background-size: calc(100% - 30px) 2px;
            padding-right: 30px;

            &:hover,
            &:focus-visible {
                background-size: 30px 2px;
            }
        }
    }

    &:hover {
        text-decoration: none;
        color: $color__link--hover;
    }

    &:focus-visible {
        color: $color__link--hover;
        // box-shadow: 0 0 0 4px $color__primary--disabled;
        outline: 4px solid $color__primary--disabled;
        // outline-offset: 0;
    }

    &.button {
        p & {
            margin-top: 20px;
        }
    }

    // body:not([data-template="detail"]) & {
    //     &:not(.stretched-link){
    //         position: relative;
    //         &:before{
    //             content: "";
    //             width: 100%;
    //             border: 1px solid $color__font;
    //             position: absolute;
    //             min-width: 10px;
    //             bottom: 0px;
    //             border-radius: 2px;
    //             left: 0; 
    //             @include transition;
    //         }
    //         @include hover-focus-visible{
    //             &:before{
    //                 width: 10px;
    //             }

    //         }

    //     }
    // }


    &.button {
        @include fluid-type(map-get($grid-breakpoints, sm), map-get($grid-breakpoints, xxl), 18px, 22px);
        background-color: $color__primary;
        background-image: none;
        padding-bottom: 16px;
        &::before {
            display: none;
        }
    }

    &.arrow {
        // display: inline-block;
        // padding-right: #{$font__line-height}em;
        // line-height: $font__line-height;

        &:hover,
        &:focus {
            &:after {
                background-position: calc(#{($font__line-height - .8) * .5}em + 4px) #{($font__line-height - .8) * .5}em;
                filter: $filter__primary--hover;
            }
        }
        &:before{
            width: calc(100% - 33px);
        }


        &::after {
            content: "";
            display: inline-block;
            position: relative;
            margin-right: -#{$font__line-height}em;
            // margin-left: 8px;
            width: #{$font__line-height}em;
            height: #{$font__line-height}em;
            vertical-align: top;
            background: {
                color: transparent;
                image: url(/bundles/hitcomfrontend/img/material-design-icons/outlined/arrow_forward_ios.svg);
                position: #{($font__line-height - .8) * .5}em #{($font__line-height - .8) * .5}em;
                repeat: no-repeat;
                size: .8em .8em;
            }
            filter: $filter__primary;
            transition: background-position .2s ease-in-out, filter .1s linear;
        }
    }
}

@media print {
    a:after {
        display: none;
        content: "";
    }
}

.headlineModul a.stretched-link {
    position: relative;
}

a.underline-link {
    &:before {
        border-color: $color__primary;
    }

    @include hover-focus-visible-within {
        &:before {
            width: 10px;
        }
    }
}