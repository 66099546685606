nav {
    ul, ol {
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
            margin: 0;
            padding: 0;

            a {
                display: block;
            }
        }
    }
}

ul {
    &.list-styled,
    .content--editor &,
    .m-p & {
        margin: 0;
        padding: 0;
        margin-left: 22px;
        list-style-type: none;

        li {
            position: relative;
            margin: 0;
            padding: 0 0 0 24px;
            line-height: $font__line-height;
            list-style: disc;
            padding: 0;

            // &::before {
            //     content: "";
            //     position: absolute;
            //     top: #{$font__line-height * .5}em;
            //     left: 0;
            //     @if $button__type == rounded {
            //         width: 8px;
            //         height: 8px;
            //         border-radius: 50%;
            //         transform: translateY(-4px);
            //     } @else {
            //         width: 14px;
            //         height: 2px;
            //         transform: translateY(-1px);
            //     }
            //     background-color: $color__primary;
            // }
            a{
                text-decoration: none;
                position: relative;

                background-color: rgba(0, 0, 0, 0);
                background-image: linear-gradient(0deg,
                        $color__black 0%,
                        $color__black 0%);
                background-repeat: no-repeat;
                background-size: 100% 2px;
                background-position: left bottom;
        
                padding-bottom: 2px;
        
                @include transition();
        
                &:hover,
                &:focus-visible {
                    background-size: 30px 2px;
                }
            }
        }
    }

    &.list-unstyled {
        margin-bottom: 0;
        @include list-unstyled();
    }
}

