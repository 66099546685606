.logo {
    &:not(.logo--footer) {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        // margin-right: auto;
        // margin-left: $grid-gutter-width * .5;
        // @media (min-width: #{map-get-last-value($container-max-widths)}) {
        //     margin-right: calc(#{map-get-last-value($container-padding-sizes)});
        //     margin-left: $container-padding-size;
        // }
        @include media-breakpoint-down(sm) {
            justify-content: flex-start;
            margin-left: 10px;
        }
    }

    &__inner {
        // display: flex;
        // flex-direction: row;
        // align-items: center;
        // height: $header__height--mobile;

        @include media-breakpoint-down(xl) {
            margin-left: 180px;
        }
        @include media-breakpoint-down(lg) {
            margin-left: 0px;
        }

        // @include media-breakpoint-up(xxl) {
        //     height: $header__height;
        //     transition: height .3s ease-in-out;
        // }

        // html.headroom--unpinned & {
        //     @include media-breakpoint-up(xxl) {
        //         height: $header__height--unpinned;
        //     }
        // }

        a {
            display: block;
            text-decoration: none;
            &:before{
                display: none;
            }

            &[tabindex='-1'] {
                pointer-events: none;
            }

            span.img {
                display: block;
                height: 76px;
                width: 241px;
                overflow: hidden;
                // line-height: 0;
                // font-size: 0;
                // color: transparent;
                @include transition;
                @include media-breakpoint-down(sm) {
                    width: 204px;
                    height: 64px;
                }
                html.headroom--unpinned & {
                    // @include media-breakpoint-up(xxl) {

                        svg{

                            #Bild{
                                opacity: 0;
                            }
                        }
                    // }
                }

                svg {
                    @include transition;
                    height: 76px;
                    width: 241px;
                    @include media-breakpoint-down(sm) {
                        width: 204px;
                        height: 64px;
                    }
                    #Bild{
                        opacity: 1;
                        @include transition;
                    }
                }
            }
        }
    }
}

html.headroom--unpinned{
    .logo__inner a span.img svg{
        @include media-breakpoint-down(sm) {
            transform: translateX(-80px);
        }
    }
    .logo__inner{
        margin-top: 0;
    }
}
.logo__inner{
    margin-top: 30px;
    @include transition;
    @include media-breakpoint-down(xxl) {
        margin-top: 0;
    }
}

