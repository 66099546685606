@mixin h1 {
    @include fluid-type(map-get($grid-breakpoints, sm), map-get($grid-breakpoints, xxl), 40px, 68px);
    font-weight: $font__weight--bold;
    line-height: 1.3392857143;
}

@mixin h2 {
    @include fluid-type(map-get($grid-breakpoints, sm), map-get($grid-breakpoints, xxl), 24px, 40px);
    font-weight: $font__weight--bold;
    line-height: 1.4;
}

@mixin h3 {
    @include fluid-type(map-get($grid-breakpoints, sm), map-get($grid-breakpoints, xxl), 20px, 30px);
    font-weight: $font__weight--bold;
    line-height: 1.4;
}

@mixin h4 {
    @include fluid-type(map-get($grid-breakpoints, sm), map-get($grid-breakpoints, xxl), 18px, 25px);
    font-weight: $font__weight--bold;
    line-height: 1.6315789474;
}

@mixin h5 {
    @include fluid-type(map-get($grid-breakpoints, sm), map-get($grid-breakpoints, xxl), 18px, 20px);
    font-weight: $font__weight--bold;
    line-height: 1.6315789474;
}

@mixin margins-headings {
    h1,
    .h1 {
        margin-bottom: $margin__headings--h1;
    }

    h2,
    .h2 {
        margin-bottom: $margin__headings--h2;
    }

    h3, h4, h5, h6,
    .h3, .h4, .h5, .h6 {
        margin-bottom: $margin__headings--h3;
    }
}

h1, h2, h3, h4, h5, h6 {
    //font-family: inherit;
    font-family: $font__family--base;
    font-size: inherit;
    font-weight: normal;
    line-height: inherit;
    color: inherit;
}

.h1, .h2, .h3, .h4, .h5, .h6 {
    display: block;
    font-family: $font__family--base;
}

// body[class*='cke_'],
// .page__main,
// .page__footer {
    h1 {
        @include h1;
        // color: $color__primary;
    }

    h2 {
        @include h2;
        // color: $color__primary;
    }

    h3 {
        @include h3;
        // color: $color__primary;
    }

    h4 {
        @include h4;
    }

    h5, h6 {
        @include h5;
    }

    .h1 {
        @include h1;
        // color: $color__primary;
    }

    .h2 {
        @include h2;
        // color: $color__primary;
    }

    .h3 {
        @include h3;
        // color: $color__primary;
    }

    .h4 {
        @include h4;
    }

    .h5, .h6 {
        @include h5;
    }
// }
.preheadline{
    display: flex;
    flex-direction: row;
    align-items: center;
    @include fluid-type(map-get($grid-breakpoints, sm), map-get($grid-breakpoints, xxl), 19px, 23px);
    margin-bottom: 0;
    font-weight: 400;
    &:before{
        content:"";
        width: 50px;
        margin-right: 15px;
        height: 2px;
        position: relative;
        display: inline-block;
        background-color: $color__primary;

    }
}
.content--heading {
    &:not(.content--margin-bottom){
        @include margins-headings 
    }    

}