/* work-sans-regular - latin */
    @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'D-DIN';
    font-style: normal;
    font-weight: 400;
    src: url('/assets/website/fonts/d-din/D-DINCondensed.woff2'); /* IE9 Compat Modes */
    }
  /* work-sans-500 - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'D-DIN';
    font-style: normal;
    font-weight: 700;
    src: url('/assets/website/fonts/d-din/D-DINCondensed-Bold.woff2');

  }
  /* work-sans-600 - latin */
//   @font-face {
//     font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
//     font-family: 'Work Sans';
//     font-style: normal;
//     font-weight: 600;
//     src: url('/assets/website/fonts/work-sans/work-sans-v18-latin-600.eot'); /* IE9 Compat Modes */
//     src: url('/assets/website/fonts/work-sans/work-sans-v18-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//          url('/assets/website/fonts/work-sans/work-sans-v18-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
//          url('/assets/website/fonts/work-sans/work-sans-v18-latin-600.woff') format('woff'), /* Modern Browsers */
//          url('/assets/website/fonts/work-sans/work-sans-v18-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
//          url('/assets/website/fonts/work-sans/work-sans-v18-latin-600.svg#WorkSans') format('svg'); /* Legacy iOS */
//   }
  /* playfair-display-regular - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'FaveScriptPro';
    font-style: normal;
    font-weight: 400;
    src: url('/assets/website/fonts/fave-script-pro/fave_script_bold-webfont.woff2'); /* IE9 Compat Modes */
   }

